<template>
    <div class="map-half-wrapper">
        <div id="mapZones"></div>
    </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import drawLocales from 'leaflet-draw-locales';
import { parse, stringify } from 'wkt';
import { mapGetters } from 'vuex';
const CustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon.png'
  }
});
export default {
    name: "mapZones",
    props: ['selectedZones'],
    data() {
        return {
            map: null,
            center: [50.4501, 30.5234],
            zoom: 5,
            startZoom: 3,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            area: null,
            createAreaModal: false,
            drawnItems: null,
            pinItems: null,
            createPayload: {
                area: null,
                expires_at: null,
                target_ids: [],
            }
        }
    },
    methods: {
        async initMap() {
            const locale = drawLocales(this.locale)
            L.drawLocal = locale;
            L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

            this.map = await L.map('mapZones', {
                zoomControl: false,
                attributionControl: false,
                center: this.center,
                zoom: this.startZoom
            });

            L.tileLayer(this.url, { maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
            L.control.zoom({
                zoomInTitle: this.$t('geo.zoomIn'),
                zoomOutTitle: this.$t('geo.zoomOut')
            }).addTo(this.map)

            var southWest = L.latLng(-89.98155760646617, -180),
                northEast = L.latLng(89.99346179538875, 180);
            var bounds = L.latLngBounds(southWest, northEast);

            this.map.setMaxBounds(bounds);
            this.map.on('drag', () => {
                this.map.panInsideBounds(bounds, { animate: false });
            });

            this.drawnItems = new L.FeatureGroup().addTo(this.map);
            this.pinItems = new L.FeatureGroup().addTo(this.map);
        },
        updateZones() {
            this.$emit('updateZones');
        },
        updateMap(){
            setTimeout(() => {
                this.map.invalidateSize();
            }, 100)
        },
        setMarker(markerObj) {
            this.pinItems.clearLayers();
            const geoJson = parse(markerObj);
            const coordinates = geoJson.coordinates;
            this.center = [coordinates[1], coordinates[0]];
            const marker = L.marker(this.center, {
              icon: new CustomMarker()
            })
            this.pinItems.addLayer(marker);
            this.map.setView(this.center)
        },
        colorZone(id) {
            const index = this.selectedZones.indexOf(id)
            const colors = ['#007bff', '#28a745', '#17a2b8', '#dc3545', '#37474f']
            return colors[index]
        }
    },
    computed: {
      locale() {
        return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
      },
      ...mapGetters('locationMonitoring', ['areas'])
    },
    watch: {
        selectedZones(val) {
            setTimeout(() => {
                this.drawnItems.clearLayers();
                this.pinItems.clearLayers();
                if(val.length === 0) return;
                const filteredAreas = this.areas.filter(area => val.includes(area.id));
                filteredAreas.forEach(area => {
                    const color = this.colorZone(area.id);
                    const layer = L.geoJSON(parse(area.area), {
                        style: {
                            color: color,
                            fillColor: color,
                            fillOpacity: 0.5
                        }
                    });
                    this.drawnItems.addLayer(layer);
                });
                if(this.drawnItems.getLayers().length) {
                    this.map.fitBounds(this.drawnItems.getBounds());
                }
            }, 2000)
        }
    },
    mounted() {
        this.initMap()
    }
}
</script>
<style>
.map-half-wrapper {
    min-height: 500px;
    height: 500px;
    width: 100%;
}

.map-half-wrapper #mapZones {
    height: 100%;
    width: 100%;
}
</style>