<template>
    <div v-if="isLoading || !showing">
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
    </div>
    <div class="priceTables"  v-else>
            <div>
              <b-table
                  v-if="sortedSingle.length > 0"
                  sticky-header
                  head-variant="light"
                  :items="sortedSingle"
                  :fields="fields"
                  :empty-text="$t('billing.empty_text')"
                  responsive
                  show-empty
                  small
                  :busy="isLoading"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(name)="row">
                  <img :src="row.item.icon_url" :alt="row.item.name" style="width: 18px"> {{ row.value }}
                </template>

                <template #cell(request_options)="row">
                  <div v-for="(option,index) in row.value" :key="index" style="display: flex; align-items: center" >
                    <div class="m-r-10"> {{ $t('params.'+option) }}</div>
                    <input :id="'optionInputSingle'+row.item.ind+index"  class="input-inside" type="number"
                        min="0"
                        :value="setOptionsValue(option, row.item.ind, 'optionInputSingle'+row.item.ind+index)"
                        @input="setOptions(option, $event.target.valueAsNumber, row.item.ind, 'optionInputSingle'+row.item.ind+index)"
                    >
                  </div>
                </template>

                <template #cell(1)="row">
                  <div style="display: flex">
                    <div class="online m-r-10">
                      <input type="number" :id="'online_result_price_single'+row.item.ind"
                             v-if="!und.isUndefined(payload.services_search_rates[row.item.ind]) && !und.isUndefined(payload.services_search_rates[row.item.ind].online_result_price)"
                             v-model.number="payload.services_search_rates[row.item.ind].online_result_price"
                             :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[row.item.ind].online_result_price.$invalid }"
                             @input="setOnlinePrice($event.target.valueAsNumber, row.item.ind, 'online_result_price_single'+row.item.ind)"
                             min="0" class="input-inside">
                    </div>
                    <div class="cache">
                      <input type="number" :id="'offline_result_price_single'+row.item.ind"
                             v-if="!und.isUndefined(payload.services_search_rates[row.item.ind]) && !und.isUndefined(payload.services_search_rates[row.item.ind].offline_result_price)"
                             v-model.number="payload.services_search_rates[row.item.ind].offline_result_price"
                             :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[row.item.ind].offline_result_price.$invalid }"
                             @input="setOfflinePrice($event.target.valueAsNumber, row.item.ind, 'offline_result_price_single'+row.item.ind)"
                             min="0" class="input-inside">
                    </div>
                  </div>

                </template>

              </b-table>



              <b-table
                  v-if="sortedMultiple.length > 0"
                  sticky-header
                  head-variant="light"
                  :items="sortedMultiple"
                  :fields="fieldsMultiple"
                  :empty-text="$t('billing.empty_text')"
                  responsive
                  show-empty
                  small
                  :tbody-tr-class="rowClass"
                  :busy="isLoading"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(name)="row" >
                  <div style="display:flex;justify-content: space-between; align-items: center;">
                    <div style="display:flex; align-items: center">
                      <img :src="row.item.icon_url" :alt="row.item.name" style="width: 18px;height: 18px; margin-right: 10px">
                      <div> {{ row.value }} </div>
                    </div>
                    <div class="switchContainer col-md-1" style="margin-top: 4px; margin-right: 15px">
                      <div class="form-check form-switch" >
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-tippy="{ placement : 'top',  arrow: true }"
                               :content="$t('tenants.switch_info')"
                               :value="row.item.ind"
                               @change="changeSwitchState(row.item.ind, row.item)"
                               v-model="selectedPriceType.services_ids">
                      </div>
                    </div>
                  </div>

                </template>

                <template #cell(request_options)="row">
                  <div  style="display: flex">
                    <div v-if="row.value.length">
                      <div v-for="(option, index) in row.value" :key="index" style="display: flex; justify-content: space-between">
                        <div class="m-r-10"> {{ $t('params.'+option) }}</div>
                        <input :id="'optionInputMulti'+row.item.ind+index" class="input-inside" type="number" required
                               min="0"
                               :value="setOptionsValue(option, row.item.ind, 'optionInputMulti'+row.item.ind+index)"
                               @input="setOptions(option, $event.target.valueAsNumber, row.item.ind, 'optionInputMulti'+row.item.ind+index)"
                        >
                      </div>
                    </div>
                  </div>
                </template>


                <template #cell(search_params)="row">
                  <div  style="display: flex">
                    <div v-if="row.item.search_params.length" class="m-r-10">
                      <div v-for="(option,index) in row.item.search_params" :key="index" style="display: flex; justify-content: space-between">
                        <div class="m-r-10">
                          {{ $t('params.'+option) }}
                        </div>
                        <input :id="'paramInput'+row.item.ind+index" :disabled="!selectedPriceType.services_ids.includes(row.item.ind)"  class="input-inside" type="number"
                               min="0"
                               :value="setParamsValue(option, row.item.ind)"
                               @input="setParams(option, $event.target.valueAsNumber, row.item.ind, 'paramInput'+row.item.ind+index)"
                        >
                      </div>
                    </div>
                  </div>
                </template>



                <template #cell(1)="row">

                  <div style="display: flex">
                    <div class="online m-r-10">
                      <input type="number" :id="'online_result_price_multi'+row.item.ind"
                             v-if="!und.isUndefined(payload.services_search_rates[row.item.ind]) && !und.isUndefined(payload.services_search_rates[row.item.ind].online_result_price)"
                             v-model.number="payload.services_search_rates[row.item.ind].online_result_price"
                             :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[row.item.ind].online_result_price.$invalid }"
                             :disabled="selectedPriceType.services_ids.includes(row.item.ind)"
                             @input="setOnlinePrice($event.target.valueAsNumber, row.item.ind, 'online_result_price_multi'+row.item.ind)"
                             min="0" class="input-inside">
                    </div>
                    <div class="cache">
                      <input type="number" :id="'offline_result_price_multi'+row.item.ind"
                             v-if="!und.isUndefined(payload.services_search_rates[row.item.ind]) && !und.isUndefined(payload.services_search_rates[row.item.ind].offline_result_price)"
                             v-model.number="payload.services_search_rates[row.item.ind].offline_result_price"
                             :class="{ 'is-invalid': !und.isUndefined($v.payload.services_search_rates.$each)
                            && $v.payload.services_search_rates.$each.$iter[row.item.ind].offline_result_price.$invalid }"
                             @input="setOfflinePrice($event.target.valueAsNumber, row.item.ind, 'offline_result_price_multi'+row.item.ind)"
                             min="0" class="input-inside">
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
    </div>
</template>
<script>
import 'core-js/actual'
import groupObj from "@/helpers/groupObj";
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
    name: 'ServiceTable',
    mixins: [validationMixin],
    setup() {
        return {
            und: _
        }
    },
    data() {
        return {
            sorted: {},
            sortedSingle: [],
            sortedMultiple: [],
            selectedPriceType: {
              services_ids: []
            },
            payload: {
                services_search_rates: []
            },
            payloadOut: [],
            showing: false,
            isLoading: true,
            validationRules: {
                payload: {
                    services_search_rates: {
                        
                    },
                }
            },
            offline_result_price: 0,
            online_result_price: 0,
            notValidVals: []
        }
    },
    validations() {
        return this.validationRules
    },
    computed: {
        ...mapGetters('service_groups', ['services']),
        fields(){
          return [
            {
              key: 'name',
              label: this.$t('monitoringLocation.name'),
              sortable: true
            },
            {
              key: 'request_options',
              label: this.$t('billing.options'),
              sortable: true
            },
            {
              key: '1',
              label: this.$t('stats.online')+' / '+this.$t('params.cache'),
              sortable: true
            },
          ]
        },
        fieldsMultiple(){
          return [
            {
              key: 'name',
              label: this.$t('monitoringLocation.name'),
              sortable: true
            },
            {
              key: 'request_options',
              label: this.$t('billing.options'),
              sortable: true
            },
            {
              key: 'search_params',
              label: this.$t('billing.params'),
              sortable: true,
              'class': 'options_block',
            },
            {
              key: '1',
              label: this.$t('stats.online')+' / '+this.$t('params.cache'),
              sortable: true,
              'class': 'online_block',
            },
          ]
        },

    },
    watch: {
        payload: {
            deep: true,
            handler(val) {
                this.$emit('services', val);
            }
        },
        '$v.$invalid': {
            handler(val) {
                this.$emit('invalid', val);
            }
        },
        notValidVals() {
          if (this.notValidVals.length > 0) {
            // this.$emit('invalid',true)
            this.$emit('invalidServices',true)
          } else {
            // this.$emit('invalid', false)
            this.$emit('invalidServices', false)
          }
        }
    },
    methods: {
        changeSwitchState(ind, tableItem){
          let isIncludes = this.selectedPriceType.services_ids.includes(ind)
          let outPayloadService = this.payloadOut.find(item=> item.source === this.payload.services_search_rates[ind].source)
          if (!isIncludes){
            this.payload.services_search_rates[ind].params = null;
          } else {
            this.payload.services_search_rates[ind].offline_result_price = outPayloadService ?  outPayloadService.offline_result_price : this.offline_result_price
            this.payload.services_search_rates[ind].online_result_price = outPayloadService ? outPayloadService.offline_result_price : this.online_result_price
            if(typeof outPayloadService !== 'undefined' && typeof outPayloadService.params !== 'undefined'){
              this.payload.services_search_rates[ind].params = outPayloadService.params
            } else {
              let serviceParams = this.services.find(item => item.name === tableItem.name).search_params
              let paramsTemp = []
              serviceParams.forEach(param => {
                paramsTemp.push({
                  name: param,
                  price: 0
                })
              })
              this.payload.services_search_rates[ind].params = paramsTemp
            }
          }
          this.$emit('services', this.payload.services_search_rates);
        },



        setOptionsValue(option, indx, elId){
          if (!_.isNull(this.payload.services_search_rates[indx].options) &&  typeof this.payload.services_search_rates[indx].options !== 'undefined'){
            let currentPrice = this.payload.services_search_rates[indx].options.find(item => item.name === option)
            if (typeof currentPrice !== 'undefined'){
              return currentPrice.price
            } else {
              return null
            }
          } else {
            let el = document.getElementById(elId)
            if (el && !this.notValidVals.includes(elId)){
              el.classList.add('is-invalid')
              this.notValidVals.push(elId)
            }
            return null
          }
        },
        setParamsValue(option, indx){
          if (!_.isNull(this.payload.services_search_rates[indx].params) && typeof this.payload.services_search_rates[indx].params !== 'undefined'){
            let currentPrice = this.payload.services_search_rates[indx].params.find(item => item.name === option)
            if (typeof currentPrice !== 'undefined'){
              return currentPrice.price
            } else return 0
          } else {
            return 0
          }
        },
        setParams(options, value, indx, elId){
          let el = document.getElementById(elId)
          if (Number.isNaN(value)){
            el.classList.add('is-invalid')
            this.notValidVals.push(elId)
          } else if (this.notValidVals.includes(elId)){
            el.classList.remove('is-invalid')
            this.notValidVals = this.notValidVals.filter(item => item !== elId)
          }
          let tempParams
          if (this.payload.services_search_rates[indx].params && typeof this.payload.services_search_rates[indx].params !== 'undefined'){
            tempParams = JSON.parse(JSON.stringify(this.payload.services_search_rates[indx].params));
            tempParams = tempParams.filter(item=> item.name !== options)
          } else {
            tempParams = []
          }
          tempParams.push({
            name: options,
            price: value
          })
          this.payload.services_search_rates[indx].params = tempParams;
          this.$emit('services', this.payload.services_search_rates);
        },
        setOptions(options, value, indx, elId){
          let el = document.getElementById(elId)
          if (Number.isNaN(value)){
            el.classList.add('is-invalid')
            this.notValidVals.push(elId)
          } else if (this.notValidVals.includes(elId)){
            el.classList.remove('is-invalid')
            this.notValidVals = this.notValidVals.filter(item => item !== elId)
          }
          let tempOptions
          if (this.payload.services_search_rates[indx].options && typeof this.payload.services_search_rates[indx].options !== 'undefined'){
            tempOptions = JSON.parse(JSON.stringify(this.payload.services_search_rates[indx].options));
            tempOptions = tempOptions.filter(item=> item.name !== options)
          } else {
            tempOptions = []
          }
          tempOptions.push({
            name: options,
            price: value
          })
          this.payload.services_search_rates[indx].options = tempOptions;
          this.$emit('services', this.payload.services_search_rates);

        },
        setOnlinePrice(val, elId){
          if (Number.isNaN(val)){
            this.notValidVals.push(elId)
          } else if (this.notValidVals.includes(elId)){
            this.notValidVals = this.notValidVals.filter(item => item !== elId)
          }
          this.$emit('services', this.payload.services_search_rates);
        },
        setOfflinePrice(val, elId){
          if (Number.isNaN(val)){
            this.notValidVals.push(elId)
          } else if (this.notValidVals.includes(elId)){
            this.notValidVals = this.notValidVals.filter(item => item !== elId)
          }
          this.$emit('services', this.payload.services_search_rates);
        },
        rowClass(item){
          if (this.selectedPriceType.services_ids.includes(item.ind)){
            return 'onlineDisabled'
          } else {
            return 'paramDisabled'
          }
        },
        sort(){
             this.sorted = Object.keys(this.sorted)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = this.sorted[key];
                    return accumulator;
                }, {});
        },
        getServices(user_id) {
            return new Promise(resolve => {
              this.$store.dispatch('service_groups/getServices', {
                    order_by: "released_at",
                    order_type: "desc",
                    filters: {
                        user_id
                    }
                }).finally(async () => {
                    const services = this.services.map((service, ind) => {
                        const addObj = {ind};
                        const getService = _.find(this.payloadOut, (ser) => ser.source === service.name);
                        if(!_.isUndefined(getService)) {
                            addObj['offline_result_price'] = getService.offline_result_price;
                            addObj['online_result_price'] = getService.online_result_price;
                        }
                        const retObj = Object.assign(service, addObj);
                        this.payload.services_search_rates[addObj.ind] = {
                            source: service.name,
                            offline_result_price: _.isUndefined(addObj['offline_result_price']) ?  this.offline_result_price: addObj['offline_result_price'],
                            online_result_price: _.isUndefined(addObj['online_result_price']) ? this.online_result_price: addObj['online_result_price'],
                            params: typeof getService !== 'undefined' && typeof getService.params !== 'undefined' ? getService.params : null ,
                            options: typeof getService !== 'undefined' && typeof getService.options !== 'undefined' ? getService.options : null
                        }
                        if (typeof getService !== 'undefined' && typeof getService.params !== 'undefined' && getService.params) {
                          this.selectedPriceType.services_ids.push(ind)
                        }
                        return retObj;
                    });
                    let single = services.filter(item=> item.search_params.length === 1)
                    let multiple = services.filter(item=> item.search_params.length > 1)
                    this.sorted = services.group(groupObj);
                    this.sort();

                    this.sortedSingle = single;
                    this.sortedMultiple = multiple;
                    this.$emit('services', this.payload.services_search_rates)
                    resolve();
                })
            })
        },
        setLoading(loading) {
            return new Promise(resolve => {
                this.isLoading = loading
                resolve(loading)
            })
        },
        setValidationRules() {
            return new Promise(resolve => {
                this.validationRules.payload.services_search_rates = {
                    $each: {
                        offline_result_price: { required },
                        online_result_price: { required },
                    }
                }
                resolve()
            })
        },
        setRates(tenant) {
            this.payloadOut = tenant.services_search_rates;
            this.offline_result_price = tenant.search_rate.offline_result_price;
            this.online_result_price = tenant.search_rate.online_result_price;
            this.showing = true;
        }
    },
    mounted() {
        this.$emit('invalid', this.$v.$invalid)
    }
}
</script>
<style lang="scss">
     .priceTables input[type="number"]:disabled {
       background: rgb(213 213 213);
     }
     .priceTables .thead-light{
       //background-color: #f8f9fa;
     }
    .thead-light th {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .onlineDisabled{
      .online_block{
        //background-color: rgb(226, 227, 229);
      }
    }

    .paramDisabled{
      .options_block{
        //background-color:  rgb(226, 227, 229);
      }
    }



    .priceTables{
      tr:first-child{
        th { width: 40% }
      }
      .table td {
        vertical-align: top;
      }
    }
    .service-tr td {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
    
    .tableFixHead tbody tr.header-tr {
        padding-top: 10px; 
        margin-top: -1px;       
    } 
    @media screen and (min-width: 992px) {
        .service-tr {
            float: left;
            /*width: 50%;*/
        }
        .service-tr:not(.ml) {
            border-right: 1px solid rgb(222 226 230);
        }
        .tableFixHead tbody tr.service-tr:last-child {
            border-bottom: 0;
        }
        .tableFixHead tbody tr.header-tr {
            border-top: 1px solid rgb(222 226 230);
        }

    }

</style>