<template>
  <div class="target">
    <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center;">
<!--      <div  class="image is-24x24">-->
<!--        <img :src="icon" :alt="icon">-->
<!--      </div>-->
      <div style="display: flex; flex-direction: column; align-items: start">

      <span style="line-height: 2rem;margin-left: 1rem; display: flex;justify-content: flex-start;align-items: center;">
        <span v-if="target.alias.length <= 25">{{target.alias}}</span>
        <span v-else :class="{'hideText': target.alias.length > 25}" :content="target.alias" v-tippy="{ placement : 'top',  arrow: true  }">{{target.alias}}</span>
        <span>:</span>
        <span class="m-l-5" v-if="target.value.length <= 25">{{target.value}}</span>
        <span v-else class="m-l-5" :class="{'hideText': target.value.length > 25}" :content="target.value" v-tippy="{ placement : 'top',  arrow: true }">{{target.value}}</span>
        <span
            v-if="target.online_statuses.length > 0 && target.online_statuses[0].source === 'Telegram' && target.online_statuses[0].status === 'recently'"
            :content="$t('monitoring.hidden')" v-tippy="{ placement : 'top',  arrow: true }"
            style="margin-left: 8px;cursor: pointer;display: flex;justify-content: center;align-items: center">
          <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 17.5C21 19.433 19.433 21 17.5 21C15.567 21 14 19.433 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5Z" stroke="#1C274C" stroke-width="1.5"/>
            <path d="M2 11H22" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M4 11L4.6138 8.54479C5.15947 6.36211 5.43231 5.27077 6.24609 4.63538C7.05988 4 8.1848 4 10.4347 4H13.5653C15.8152 4 16.9401 4 17.7539 4.63538C18.5677 5.27077 18.8405 6.36211 19.3862 8.54479L20 11" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M10 17.5C10 19.433 8.433 21 6.5 21C4.567 21 3 19.433 3 17.5C3 15.567 4.567 14 6.5 14C8.433 14 10 15.567 10 17.5Z" stroke="#1C274C" stroke-width="1.5"/>
            <path d="M10 17.4999L10.6584 17.1707C11.5029 16.7484 12.4971 16.7484 13.3416 17.1707L14 17.4999" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </span>
      </span>
      <p v-if="target.note" class="text-muted m-b-5" style="margin-left: 1rem; text-align: start; max-width: 95%">{{$t('monitoring.note')}}:  {{target.note}}</p>
      </div>


    </div>


    <span v-if="!isEdit && !target.active && !loading && currentMonitoringCount < 5" @click="startMonitoring"  :content="$t('monitoring.start_monitoring')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-play f-w-600 f-16 playColor"></i>
    </span>

    <span v-if="!isEdit && target.active && !loading" @click="stopMonitoring"  :content="$t('monitoring.stop_monitoring')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-pause f-w-600 f-16 stopColor"></i>
    </span>
    <span v-if="!target.active && !loading && !isEdit && currentMonitoringCount >= 5" style="display: flex;align-items: center;cursor: pointer">  <i class="feather icon-alert-circle f-w-600 f-16 text-warning"  :content="$t('monitoring.max_target_count')" v-tippy="{ placement : 'top',  arrow: true }"></i></span>


    <span v-if="loading" style="display: flex;align-items: center">
      <b-spinner small></b-spinner>
    </span>

    <span v-if="isEdit" @click="unsubscribe"  :content="$t('monitoring.delete_target')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-trash-2 f-w-600 f-16 text-c-red" style="margin-right: 8px"></i>
    </span>

    <span v-if="isEdit" @click="editTarget"  :content="$t('monitoring.edit_target')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-edit f-w-600 f-16 editIconColor"></i>
    </span>

    <update-target
        ref="updateModal"
        :target="target"
    />

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UpdateTarget from "@/components/monitoring/updateTarget";

export default {
  name: "target",
  components: {UpdateTarget},
  props: ['target', 'isEdit'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('monitoring', ['sources', 'currentMonitoringCount']),
    icon(){
      let nans = this.sources
      let mm = nans.data.filter((item) => {return item.monitoring_params.includes(this.target.param)})
      let ans = mm.map(item => item.icon_url);
      return ans[0];
    },
  },
  methods: {
    startMonitoring() {
      this.loading = true
      this.$store.dispatch('monitoring/startMonitoring', this.target.id).then(()=>{
        // this.isLoading = false;
        this.$store.dispatch('monitoring/getTargets').then(() => {
          this.loading = false
        })
      }).catch(()=>{
        this.loading = false;
      })
    },
    stopMonitoring() {
      this.loading = true
      this.$store.dispatch('monitoring/stopMonitoring', this.target.id).then(()=>{
        // this.isLoading = false;
        this.$store.dispatch('monitoring/getTargets').then(() => {
          this.loading = false
        })
      })
    },

    unsubscribe() {
      this.$store.dispatch('monitoring/unsubscribe', this.target.id)
    },
    editTarget(){
      this.$refs.updateModal.showDialog()
    },
  }
}
</script>

<style lang="scss">
  .editIconColor{
    color: $primary-color !important;
  }
  .playColor{
    color: #2ed8b6;
  }
  .playColor:disabled {
    color: #ededed; 
  }
  .stopColor{
   color:  #ff5370;
  }
  .target{
    display: flex;
    text-align: center;
    justify-content: space-between;
    border-top:  1px solid rgba(0, 0, 0, 0.15);
  }
  .target:last-child {
    border-bottom:  1px solid rgba(0, 0, 0, 0.15);
  }
  .hideText {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>