<template>
    <div class="services-block-search" v-show="iterative || (!und.isUndefined(searchOptions[currentParam]) && searchOptions[currentParam].services.length)">
        <div v-if="isServicesLoading" class="p-t-10">
            <div class="panel-block">
                <b-skeleton :animated="true"></b-skeleton>
            </div>
            <div class="panel-block">
                <b-skeleton :animated="true"></b-skeleton>
            </div>
            <div class="panel-block">
                <b-skeleton :animated="true"></b-skeleton>
            </div>
            <div class="panel-block">
                <b-skeleton :animated="true"></b-skeleton>
            </div>
            <div class="panel-block">
                <b-skeleton :animated="true"></b-skeleton>
            </div>
        </div>
        <div v-else class="p-t-10">
            <div class="group" v-for="group in usedKeys" v-if="iterative && group !== currentParam && groupedServices[group].some(item => !item.search_params.includes(currentParam)) || group === currentParam" :key="group">
                <div class="paramTitle" v-if="usedKeys.length > 1 && groupedServices[group].length > 0">{{ $t(`params.${group}`) | capitalize }}</div>
                <div class="sources-columns border-checkbox-section">
                    <div class="group">
                      <service-check v-for="service in groupedServices[group]" 
                        v-if="!service.search_params.includes(currentParam) && iterative && group !== currentParam || currentParam == group" 
                        :key="service.id" 
                        :param="servicesClass.param"
                        :iterative="iterative"
                        :group="group" 
                        :isChecked="payload.services.includes(service.name)" 
                        :ref="`serviceCheck_${service.name}`"
                        :service="service" @toggleService="serviceToPayload" @pushOptions="syncOptions" @pushPrice="addPrice" />
                        <!-- <div v-for="service in groupedServices[group]" :key="service.id"
                            class="border-checkbox-group border-checkbox-group-primary "
                            style="width: 100%;padding-bottom: 3px">
                            <div>
                              <input type="checkbox" class="border-checkbox" :id="`service_${service.id}`"
                                     v-model="payload.services" :value="service.name">

                            <label :for="`service_${service.id}`" class="border-checkbox-label checkboxFlex">
                              <div style="width: 12rem">{{ service.name }}<span class="coast" v-if="service.offline_result_price > service.online_result_price"> ({{ service.offline_result_price}})</span>
                                <span class="coast" v-else> ({{ service.online_result_price}}{{ $t('servicesSelect.c') }})</span></div>
                              </label>
                            </div> -->
                          <!-- <div class="border-checkbox-group border-checkbox-group-primary " v-if="service.request_options.length  && param === 'isdn' && group === param" style="margin-top: 2px">
                            <input
                                type="checkbox"
                                class="border-checkbox"
                                :id="service.name+'_'+service.request_options.name"
                                v-model="getContactTags"
                                :value="service.source"
                                :disabled="!getContactSelected"
                                @change="setGetContactTags"
                            >
                            <label :for="service.name+'_'+service.request_options.name" class="border-checkbox-label checkboxFlex">
                              <div style="display: flex">
                                <div style="width: 12rem; text-transform: capitalize">{{ $t('visualOptions.get_contact_true') }}</div>
                              </div>
                            </label>
                          </div> -->


                          <!-- <div class="border-checkbox-group border-checkbox-group-primary " v-if="service.request_options.length  && (param === 'imsi' ||  param === 'imei') && group === param"  style="margin-top: 2px">
                            <input
                                type="checkbox"
                                class="border-checkbox"
                                :id="service.name+'_'+service.request_options.name"
                                v-model="locatorCoords"
                                :value="service.source"
                                :disabled="!locatorSelected"
                                @change="setLocatorCoords"
                            >
                            <label :for="service.name+'_'+service.request_options.name" class="border-checkbox-label checkboxFlex">
                              <div style="display: flex">
                                <div style="text-transform: capitalize">{{ $t('monitoringLocation.get_coordinates') }}</div>
                              </div>
                            </label>
                          </div> -->


                        <!-- </div> -->
                    </div>
                </div>
            </div>

           <div>
             <div v-if="totalPrice.length > 0 && !iterative" style="width: 100%;margin-top: 8px; font-weight: 700">{{ $tc('servicesSelect.used', totalPrice.length ,{ servicesLength: totalPrice.length, totalPrice: totalPrice.totalPrice }) }}{{ $t('servicesSelect.c') }}</div>
             <div v-if="totalPrice.length > 0 && iterative" style="width: 100%;margin-top: 8px; font-weight: 700">{{ $t('servicesSelect.used_iterative', { servicesLength: totalPrice.length }) }}</div>
           </div>

        </div>
    </div>
</template>
<script>
import { Services } from '@/models/searchOptions/Servises';
import { mapGetters } from 'vuex';
import {validationMixin} from 'vuelidate'
import { required } from 'vuelidate/lib/validators';
import filters from '@/mixins/filters';
import _ from "underscore";
import serviceCheck from './serviceCheck.vue';
export default {
    setup() {
      return {
        und: _
      }
    },
    components: {
        serviceCheck
    },
    name: 'Sevices',
    props: ['param'],
    mixins: [filters, validationMixin],
    data() {
        return {
            payload: {
                services: []
            },
            servicesClass: null,
            isServicesLoading: false,
            additionalPrice: {},
            paramKeys: [
                'isdn',
                'image',
                'email',
                'username',
                'car_plate_num',
                'telegram_id',
                'social_url',
                'imsi',
                'imei'
            ],
            iterative: false,
            servicesArray: [],
            getContactTags: false,
            locatorCoords: false,
            searchSources: {},
            totalPrice: {
              totalPrice: 0,
              length: 0
            },
           // service_options : {
           //    GetContact: {
           //      request_options: {
           //        tags: true
           //      }
           //    }
           //  }
           GetContact: {
                GetContact: {
                  request_options: {
                    tags: true
                  }
                }
           },
          Locator: {
            Locator: {
              request_options: {
                location: true
              }
            }
          }
        }
    },
    validations() {
      return {
        servicesArray: {
          requiredServices: (val, vm) =>{
            return vm.iterative && vm.searchSources.online_search_enabled && !vm.searchSources.cache_search_enabled ? val.length > 0 : true;
          }
        }
      }
    },
    computed: {
        ...mapGetters('search_rates', ['search_rates', 'servicesList']),
        ...mapGetters('contacts', ['searchOptions']),
        currentParam() {
            return this.param === 'mass_isdn' ? 'isdn' : this.param
        },
        usedKeys() {
            return Object.keys(this.groupedServices).filter(serviceKey => {
              return this.iterative && ((this.currentParam !== 'image' && serviceKey !== 'image') || (this.currentParam === 'image' && serviceKey === 'image')) ? true : serviceKey.toLowerCase() === this.currentParam.toLowerCase()
            })
        },
        groupedParams() {
          const groupedParams = {}
          for(const param of this.paramKeys){
            if (typeof this.searchOptions[param] !=='undefined'){
              groupedParams[param] = _.indexBy(this.searchOptions[param].services, 'name')
            }
          }
          return groupedParams
        },
        groupedServices() {
            const servicesObj = {};
            if (this.servicesList.length) {
                for (const param of this.paramKeys) {
                  if (typeof this.searchOptions[param] !=='undefined'){
                    servicesObj[param] = this.servicesList.filter(item => item.search_params.includes(param))
                        .map(item => {
                          const iterItem = JSON.parse(JSON.stringify(item))
                          const priceSource = _.findWhere(this.search_rates.services_search_rates, {source: iterItem.name})
                          const defaultPrice = this.search_rates.defaults
                          iterItem.request_options = this.groupedParams[param][item.name].request_options
                          let onlinePrice = defaultPrice.online_result_price;
                          if(!_.isUndefined(priceSource) && priceSource.params.length) { 
                            const objParams = _.indexBy(priceSource.params, 'name')
                            onlinePrice = !_.isUndefined(objParams[param]) ? objParams[param].price : defaultPrice.online_result_price
                          } else if(!_.isUndefined(priceSource) && priceSource.online_result_price && priceSource.params.length === 0) {
                            onlinePrice = priceSource.online_result_price
                          }
                          return Object.assign(iterItem, {
                            offline_result_price: !_.isUndefined(priceSource) ? priceSource.offline_result_price : defaultPrice.offline_result_price,
                            online_result_price: onlinePrice,
                            price_options: !_.isUndefined(priceSource) ? priceSource.options : [],
                            price_params: !_.isUndefined(priceSource) ? priceSource.params : []
                          })
                        })
                  }
                }
                
                return servicesObj;
            } else {
                return {};
            }
        },
      //   getContactSelected(){
      //     if (this.payload.services.length){
      //       return this.payload.services.includes("GetContact");
      //     } else {
      //       return false
      //     }
      //   },
      // locatorSelected(){
      //   if (this.payload.services.length){
      //     return this.payload.services.includes("Locator");
      //   } else {
      //     return false
      //   }
      // },
    },
    methods: {
        pushValidation() {
            this.$emit('invalid', this.$v.$invalid)
        },
        addPrice(price){
          this.additionalPrice = Object.assign(this.additionalPrice, price)
        },
        getPrice(list){
          let price = {
            totalPrice: 0,
            length: 0
          }
          if (!_.isUndefined(list) && list.length && Object.keys(this.search_rates).length){
            let temp = list
            temp = Array.from(new Set(temp));
            price.length = temp.length
            // temp.forEach(selectedService=> {
            //   let currentPrice = this.search_rates.services_search_rates.find(rateItem=> rateItem.source === selectedService)
            //   if (typeof currentPrice !== 'undefined'){
            //     if (currentPrice.online_result_price > currentPrice.offline_result_price){
            //       price.totalPrice = price.totalPrice + currentPrice.online_result_price
            //     } else {
            //       price.totalPrice = price.totalPrice + currentPrice.offline_result_price
            //     }
            //   } else {
            //     if (this.search_rates.defaults.online_result_price > this.search_rates.defaults.offline_result_price){
            //       price.totalPrice = price.totalPrice + this.search_rates.defaults.online_result_price
            //     } else {
            //       price.totalPrice = price.totalPrice + this.search_rates.defaults.offline_result_price
            //     }
            //   }
            // })
          }
          const additionalPrice = Object.values(this.additionalPrice).reduce((acc, item) => acc + item, 0)
          price.totalPrice = additionalPrice
          this.totalPrice = price;
        },

        setGetContactTags(){
          localStorage.setItem('getContactTags', JSON.stringify(this.getContactTags));
          if (this.getContactTags && this.param === 'isdn'){
            // this.payload.service_options = this.service_options
            if (typeof this.payload.service_options !== 'undefined'){
              this.payload.service_options = this.GetContact
            } else {
              // this.payload.service_options = []
              this.payload.service_options = this.GetContact
            }
          } else {
            delete  this.payload.service_options
          }
          this.$emit('serviceOptions', { service_options: this.payload.service_options })
        },



      setLocatorCoords(){
        if (this.locatorCoords && (this.param === 'imei' || this.param === "imsi")){
          // this.payload.service_options = this.service_options
          if (typeof this.payload.service_options !== 'undefined'){
            this.payload.service_options = this.Locator
          } else {
            // this.payload.service_options = []
            this.payload.service_options = this.Locator
          }
        } else {
          delete  this.payload.service_options
        }
        this.$emit('serviceOptions', { service_options: this.payload.service_options })
      },



        getSearchRates() {
            this.$store.dispatch('search_rates/getSearchRates').then(() => {
                this.$emit('ratesLoading', true)
                //   this.getServicesArr()
            })
        },
        getServices() {
            this.isServicesLoading = true;
            this.$emit('ratesLoading', true)
            Promise.all([
                this.$store.dispatch('search_rates/getServices'),
                this.$store.dispatch('search_rates/getSearchRates')
            ]).finally(() => {
              this.isServicesLoading = false;
              this.$emit('ratesLoading', false)
              this.reduceServices();
            })
        },
        setAlgorithms(data) {
            this.iterative = data.iterative_search;
            this.reduceServices()
            this.pushValidation();
        },
        reduceServices() {
            let servicesArray = this.searchOptions[this.currentParam].services.filter(service => {
                return this.payload.services.includes(service.name);
            })
            this.servicesArray = servicesArray.map(item => { return item.name })
            if (this.iterative) {
                this.$emit('push', this.payload.services.length ? this.payload : { services: undefined })
                setTimeout(() => {
                  this.getPrice(this.payload.services)
                }, 100)
            } else {
                let services;
                if (this.searchOptions[this.currentParam].services.length) {
                    services = servicesArray.length ? this.servicesArray : undefined
                    
                }
                this.$emit('push', { services: services })
                setTimeout(() => {
                  this.getPrice(services)
                }, 100)
                this.setDefaultGetContact()
            }
        },
        setDefaultGetContact(){
          let obj = JSON.parse(localStorage.getItem('getContactTags'));
          if (!obj || this.param !== 'isdn'){
            this.getContactTags = obj
            if (typeof this.payload.service_options !== 'undefined'){
              delete  this.payload.service_options
            }
          } else {
            this.getContactTags = obj
            this.payload.service_options = this.service_options
          }
          this.$emit('serviceOptions', { service_options: this.payload.service_options })
        },
        setSearchSources(data) {
          this.searchSources = data;
          this.pushValidation();
            if ((data.online_search_enabled && this.servicesArray.length === 0 && !this.iterative) 
                || data.online_search_enabled && this.iterative && this.payload.services.length === 0) {
                for (const usedKey of this.usedKeys) {
                    this.payload.services = _.uniq([...this.payload.services, ...this.searchOptions[usedKey].services.map(service => service.name)])
                    // for (const service of this.searchOptions[usedKey].services.map(service => service.name)) {
                    //     this.$refs[`serviceCheck_${service}`].setPayloadTrue();
                    // }
                }
            } else if(!data.online_search_enabled && this.servicesArray.length > 0 
                || this.iterative && !data.online_search_enabled && this.payload.services.length) {
                this.servicesArray = [];
                this.payload.services = [];
            }
        },
        serviceToPayload(service) {          
            if (service.value) {
                this.payload.services = _.uniq([...this.payload.services, service.service])
            } else {
                this.payload.services = this.payload.services.filter(item => item !== service.service)
            }
            this.reduceServices();
            this.pushValidation();
        },
        syncOptions(options) {          
          if(_.isUndefined(this.payload.service_options)){
            this.payload.service_options = {}
          }
          const assignOptions = Object.assign(this.payload.service_options, options)
          this.payload.service_options = assignOptions;
          this.$emit('serviceOptions', { service_options: this.payload.service_options })
        }
    },
    async mounted() {
        this.servicesClass = new Services(this.param);
        this.payload.services = this.servicesClass.options;
        await this.getServices();
        await this.reduceServices();
        await this.pushValidation();
    },
    watch: {
        'payload.services': {
            handler(val) {
                this.servicesClass.updateOptions(val)
                this.reduceServices();
                this.pushValidation();
            }
        },
        getContactSelected(){
          if (!this.getContactSelected){
            this.getContactTags = false
          }
          // this.setGetContactTags();
        },
        locatorSelected(){
          if (!this.locatorSelected){
            this.locatorCoords = false
          }
        },
    }
}
</script>
<style>
.ml-4 {
    margin-left: 4px;
}

.childPhotoSearch {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

img.service-icon {
    width: 20px;
}

.paramTitle {
    font-weight: 600;
    padding-left: 1rem;
    margin-bottom: 8px;
    margin-top: 8px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-left: 0;
}

.services-block-search .sources-columns {
    column-count: 3;
}

.modal-content .sources-columns{
  column-count: 2;
}

@media (max-width: 992px) {
  .services-block-search .sources-columns {
        column-count: 1;
    }
    .modal-content .sources-columns {
        column-count: 1;
    }
}

.services-block-search .sources-columns {
    position: relative;
}

.services-block-search .sources-columns .border-checkbox-section .border-checkbox-group .border-checkbox-label {
    margin-bottom: 10px;
    height: 60px;
}

.services-block-search .sources-columns .user-link {
    display: inline-block;
}

.services-block-search .sources-columns .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox {
    width: 100%;
}

.services-block-search .sources-columns .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .check {
    margin-right: calc(0.75em - 1px);
}

.services-block-search .sources-columns .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .control-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0;
    position: relative;
}

.services-block-search .sources-columns .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .control-label:hover {
    background-color: #fff;
    overflow: visible;
    text-overflow: initial;
    text-decoration: underline;
    z-index: 99;
    padding-right: 20px;
}
.services-block-search {
    margin-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
}
</style>