import axios from "../../configs/axios";
import env from '../../configs/env.json';
// import moment from "moment";
import _ from "underscore";
import moment from "moment";

function getDay(start) {
    let weekDays = [0, 86400, 172800, 259200, 345600, 432000, 518400];
    let index = 0;
    for(let i = 0; i < 7; i++) {
        if( start >= weekDays[i]) {
            index = i;
        } else {
            return index;
        }
    }
    return index;
}

function resolveEvents(eventsArray) {
    return eventsArray.map(item => {
        return {
            date: new Date(item.created_at),
            type: 'is-danger'
        }
    })
}

function segmentize(tasks) {
    const segments = [];
    for (let i = 0; i < tasks.length; i++) {
        const segmentStart = tasks[i].finished_at;
        const segmentEnd = !_.isUndefined(tasks[i+1]) ? tasks[i + 1].started_at: moment().format('X');
        segments.push({ start: segmentStart, end: segmentEnd });
    }
    return segments;
}
let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.monitoringEndpoint = `https://monitoring.${host}/api`
}

const domain = env.monitoringEndpoint;
export default {
    namespaced: true,
    state: {
        targets: [],
        history: [],
        onlineStatuses: [],
        groupedStatuses: {},
        groupedEvents: {},
        startWeek: 0,
        weekDays: [0, 86400, 172800, 259200, 345600, 432000, 518400],
        sources: [],
        adminSources: [],
        selectedTargets: [],
        monthStatuses: [],
        selectedIndexes: [],
        lastOnline: 0,
        targetsFromSearch: [],
        colors: {},
        countOfResponse: 0,
        totalCountOfResponse: 0,
        currentSource: 'monitoring',
        currentMonitoringCount: 0,
        targetsPayload: {},
        selectedSources: 'event_monitors',
        targetLoad: false,
        pauses: null,
        groupedTasks: {}
    },
    getters: {
        targets: (state) => state.targets,
        groupedStatuses: (state) => state.groupedStatuses,
        sources: (state) => state.sources,
        adminSources: state => state.adminSources,
        monthStatuses: (state) => state.monthStatuses,
        targetsFromSearch: (state) => state.targetsFromSearch,
        colors: (state) => state.colors,
        progress: state => state.totalCountOfResponse === 0 ? 100 : (state.countOfResponse/state.totalCountOfResponse)*100,
        currentMonitoringCount: state => state.currentMonitoringCount,
        selectedSources: state => state.selectedSources,
        targetLoad: state => state.targetLoad,
        pauses: state => state.pauses,
        groupedTasks: state => state.groupedTasks
    },
    actions: {
        startMonitoring(context, payload) {
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            if (!innerPayload.period){
                delete innerPayload.period
            }
            if (innerPayload.service_ids && innerPayload.service_ids.length === 0){
                delete innerPayload.service_ids
            }
            return new Promise((resolve, reject) => {
                axios({url: `/monitoring_task/${payload.id}`, baseURL: domain, method: 'POST', data: innerPayload })
                    .then((resp) => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        stopMonitoring(context, payload) {
            let innerPayload = JSON.parse(JSON.stringify(payload))
            delete innerPayload.id
            return new Promise((resolve, reject) => {
                axios({url: `monitoring_task/${payload.id}/stop`, baseURL: domain, method: 'GET'})
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        subscribe(context, targetId) {
            return new Promise((resolve, reject) => {
                axios({url: `monitoring/subscribe/${targetId}`, baseURL: domain})
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        updateTarget({commit}, payload){
            return new Promise((resolve, reject) => {
                axios({url: `subscription/${payload.id}`, data: payload.payload, baseURL: domain, method: 'PUT' })
                    .then(resp => {
                        resolve(resp.data);
                        // commit('deleteStatePayload', targetId);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        updateTask({commit}, payload){
            let innerPayload = JSON.parse(JSON.stringify(payload.payload))
            delete innerPayload.id
            if (!innerPayload.period){
                delete innerPayload.period
            }
            if (innerPayload.service_ids &&  innerPayload.service_ids.length === 0){
                delete innerPayload.service_ids
            }
            return new Promise((resolve, reject) => {
                axios({url: `/monitoring_task/${payload.id}`, baseURL: domain, method: 'PUT', data: innerPayload })
                    .then((resp) => {
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        unsubscribe( context, targetId) {
            return new Promise((resolve, reject) => {
                axios({url: `subscription/${targetId}`, baseURL: domain, method: 'DELETE'})
                    .then(resp => {
                        resolve(resp.data);
                        context.commit('deleteStatePayload', targetId);
                        context.commit('setCurrentMonitoringCount', context.state.targets);

                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
        getTargets(context, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `subscriptions`, data: payload,  method: 'POST', baseURL: domain})
                    .then(resp => {
                        context.commit('setTargets', resp.data.data);
                        context.commit('setCurrentMonitoringCount', resp.data.data);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getHistory(context, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `history`, data: payload,  method: 'POST'})
                    .then(resp => {
                        context.commit('setTargets', resp.data);
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getOnlineStatuses(context, payload) {
            context.commit('setStartWeek', payload.filters.created_from);
            context.commit('setOnlineStatuses')
            if(context.getters.progress === 100) {
                context.state.targetLoad = true;
            }
            return new Promise((resolve, reject) => {
                axios({url: `online_statuses`, data: payload, baseURL: domain, method: 'POST'})
                    .then(resp => {
                        context.state.targetLoad = false;
                        if(resp.data.total > payload.limit) {
                            payload.offset = payload.offset + payload.limit;
                            context.dispatch('addToEvents', payload)
                        }
                        context.commit('setTotalCountOfResponse', resp.data.total);
                        context.commit('setStatuses', resp.data.data);
                        context.commit('setStatusesAfterUpdate');
                        context.commit('setCountOfResponse');
                        resolve(resp.data.data)
                    })
                    .catch(err => {
                        reject(err)
                        // context.commit('setTotalCountOfResponse', 0);
                    })
            })
        },
        clearOnlineStatuses({commit}) {
            commit('setOnlineStatuses');
        },
        getGroupedOnlineStatuses(context, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `online_statuses`, data: payload, baseURL: domain, method: 'POST'})
                    .then(resp => {
                        if(context.state.currentSource === payload.source) {
                            context.commit('setMonthStatuses', resp.data);
                            resolve(resp.data.data)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createTarget(context, payload) {
            return new Promise((resolve, reject) => {
                axios({url: `subscription`, data: payload, baseURL: domain, method: 'POST'})
                    .then(resp => {
                        // if (!resp.data.active && context.state.currentMonitoringCount < 5){
                        //     context.dispatch('startMonitoring', resp.data.id)
                        // }
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getSelectedTargets(context, payload){
            context.commit('setSelectedTargets', payload);
            context.commit('setColors');
        },
        getSelectedSources({commit}, payload) {
            commit('setSelectedSources', payload)
        },
        getSources(context , payload){
            return new Promise((resolve, reject) => {
                axios({url: 'services', data: payload, baseURL: domain, method: 'POST'})
                    .then( resp =>{
                        context.commit('setSources', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAdminSources(context , payload){
            return new Promise((resolve, reject) => {
                axios({url: 'admin/services', data: payload, baseURL: domain, method: 'POST'})
                    .then( resp =>{
                        context.commit('setAdminSources', resp.data)
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        lastOnline(context, payload) {
            return new Promise((resolve, reject) => {
                const data = JSON.parse(JSON.stringify(payload))
                axios({url: 'online_statuses', data, baseURL: domain, method: 'POST'})
                    .then( resp =>{
                        // context.commit('setLastOnline', payload)
                        resolve(resp.data.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        addTargetFromSearch(context, payload){
            context.commit('setTargetsFromSearch', payload)
        },
        addToEvents(context, payload) {
            return new Promise((resolve, reject) => {
                const data = JSON.parse(JSON.stringify(payload))
                axios({url: 'online_statuses', data, baseURL: domain, method: 'POST'})
                    .then( resp =>{
                        context.commit('setAdditionEvents', resp.data.data);
                        context.commit('setStatusesAfterUpdate');
                        context.commit('setCountOfResponse');
                        if(resp.data.total > payload.offset + payload.limit) {
                            payload.offset = payload.offset + payload.limit;
                            context.dispatch('addToEvents', payload);
                        }
                        resolve(resp.data.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        removeTargets(context){
            context.commit('setRemoveTargets')
        },
        currentSourceAction({commit}, source) {
            commit('setCurrentSource', source);
        },
        getTasks({commit}, data) {
            return new Promise((resolve, reject) => {
                axios({url: 'monitoring_tasks', data, baseURL: domain, method: 'POST'})
                    .then(resp => {
                        resolve(resp.data);
                        commit('setTasks', resp.data)
                    })
            })
        },
        clearPauses({commit}) {
            commit('setCleanPauses');
        }
    },
    mutations: {
        setTargets(state, payload) {
            let arr =  payload.sort(function (a, b) {
              return b.active - a.active
            })
            state.targets = arr;
        },
        setCurrentMonitoringCount(state, payload){
            let count = 0;
            payload.forEach(target => {
                if (target.active){
                    count++
                }
            })
            state.currentMonitoringCount = count;
        },
        setHistory(state, payload) {
            state.history = payload;
        },
        setStatuses(state, payload) {
            let groupedStatuses = _.groupBy(payload, (item) => item.target_id);
            let onlinePeriods = [];
            let keysGrouped = Object.keys(groupedStatuses);
            for (let key of keysGrouped) {
                let group = groupedStatuses[key];
                for(let groupKey in group) {
                    onlinePeriods.push(group[groupKey]);
                }
            }
            state.onlineStatuses = onlinePeriods
        },
        setTasks(state, payload) {
            const groupedTasks = _.groupBy(payload.data, 'subscription_id');
            const groupedPauses = {};
            for(const targetId of Object.keys(groupedTasks)) {
                groupedPauses[targetId] = segmentize(groupedTasks[targetId])
            }
            state.pauses = groupedPauses
        },
        deleteStatePayload(state, targetId) {
            state.targets.splice(
                state.targets.findIndex(v => v.id === targetId),
                1
            );
        },
        setStartWeek(state, payload) {
            state.startWeek = parseInt(payload);
        },
        setSelectedTargets(state, payload){
            state.selectedTargets = payload;
            state.selectedIndexes = payload.map(item => {
                return item.target_id
            })
        },
        setSources(state, payload){
            const sourcesPriority = {
                'Telegram': 1,
                'Viber': 2,
                'WhatsApp': 3,
                'VKMonitor': 4,
            };
            payload.data = payload.data
                .map(item => Object.assign(item, {priority: !_.isUndefined(sourcesPriority[item.name]) ? sourcesPriority[item.name] : 10 }))
                .sort((a, b) => a.priority - b.priority)
            state.sources = payload
        },
        setAdminSources(state, payload){
            state.adminSources = payload
        },
        setMonthStatuses(state, payload) {
            state.monthStatuses = resolveEvents(payload)
        },
        setLastOnline(state, payload) {
            state.lastOnline = payload
        },
        setTargetsFromSearch(state, payload) {
            state.targetsFromSearch = payload
        },
        setColors(state) {
            let colors = [];
            for (let i = 0; i < 5; i++ ) {
                colors.push(`color-${i}`)
            }
            let selectedColors = [];
            for(let ind in state.selectedIndexes) {
                selectedColors[state.selectedIndexes[ind]] = colors[ind]
            }
            state.colors = selectedColors;
        },
        setAdditionEvents(state, payload) {
            state.onlineStatuses =  state.onlineStatuses.concat(payload);
        },
        setStatusesAfterUpdate(state) {
            let events = []
            let index = 0
            state.onlineStatuses.forEach((item) => {
                let isShift =  moment(item.created_at, 'X').isDST() !==  moment(state.startWeek, 'X').isDST()
                let daySeconds = 86400
                let shiftCoef = 0;
                if (isShift && moment(item.created_at, 'X').isDST()){
                    shiftCoef -= 3600
                } else if (isShift && !moment(item.created_at, 'X').isDST()){
                    shiftCoef += 3600
                }
                let start = item.created_at - state.startWeek
                let day = moment(item.created_at, 'X').diff(moment(state.startWeek, 'X'), "days")

                let percent = 100 * (start - shiftCoef - day * daySeconds)/(60*60*24-1);
                let classBind = {};
                const {alias} = _.findWhere(state.targets, {target_id: item.target_id})
                classBind[state.colors[item.target_id]] = true;
                let startTime = moment(item.created_at, 'X').format('HH:mm');
                let endTime = moment(item.updated_at, 'X').format('HH:mm');

                // detect how many days was target online
                let width;
                let dayWidth;
                let title = `${alias} (${item.source === 'VKMonitor'? 'VKontakte': item.source}) : ${startTime} - ${endTime}`;
                if (moment(item.created_at, 'X').isSame( moment(item.updated_at, 'X'), 'day')){
                    let onlineTimeLength = moment(item.updated_at, 'X').diff(moment(item.created_at, 'X'), "minutes")
                    if (onlineTimeLength === 0){
                        width = 0.8133
                        dayWidth = 18.433
                    } else {
                        width = onlineTimeLength * 0.8133
                        dayWidth = onlineTimeLength * 18.433
                    }
                    events.push({
                        id: index,
                        color: "#6aa84f",
                        start,
                        day,
                        width,
                        dayWidth,
                        percent,
                        created_at: item.created_at,
                        updated_at: item.updated_at,
                        classBind,
                        title,
                        status: item.status
                    })
                    index++
                } else {
                   let daysOnline = moment(item.updated_at, 'X').startOf('day').diff(moment(item.created_at , 'X').startOf('day'), 'day')
                    let currentDay = 0
                    for (currentDay; currentDay <= daysOnline; currentDay++){
                        let onlineTimeLength;
                        if (currentDay === 0){
                            onlineTimeLength = moment(item.created_at , 'X').endOf('day').diff(moment(item.created_at, 'X'), "minutes")
                            width = (onlineTimeLength * 0.80133)
                            dayWidth = onlineTimeLength * 18.433
                            events.push({
                                id: index,
                                color: "#6aa84f",
                                start,
                                day,
                                width,
                                dayWidth,
                                percent,
                                created_at: item.created_at,
                                updated_at: item.updated_at,
                                classBind,
                                title,
                                status: item.status
                            })
                            index++
                        } else if (currentDay === daysOnline){
                            onlineTimeLength = moment(item.updated_at , 'X').diff(moment(item.updated_at, 'X').startOf('day'), "minutes")
                            width = onlineTimeLength * 0.80133
                            dayWidth = onlineTimeLength * 18.433333
                            events.push({
                                id: index,
                                color: "#6aa84f",
                                start,
                                day: day + daysOnline,
                                width,
                                dayWidth,
                                percent: 0,
                                created_at: item.created_at,
                                updated_at: item.updated_at,
                                classBind,
                                title,
                                status: item.status
                            })
                            item.status
                            index++
                        } else {
                            width = 1440 * 0.80133
                            dayWidth = 1440 * 18.43333
                            events.push({
                                id: index,
                                color: "#6aa84f",
                                start,
                                day: day + daysOnline,
                                width,
                                dayWidth,
                                percent: 0,
                                created_at: item.created_at,
                                classBind,
                                title,
                                status: item.status
                            })
                            item.status
                            index++
                        }
                    }
                }

            })

            state.groupedEvents = _.groupBy(events, (item) => item.day)
        },
        setOnlineStatuses(state) {
            state.onlineStatuses = [];
            state.groupedEvents = {};
        },
        setRemoveTargets(state){
            state.targets = [];
        },
        setCountOfResponse(state) {
            let filteredEvents = state.onlineStatuses.filter(it => {
                return it.created_at >= state.startWeek
            });
            state.countOfResponse =  filteredEvents.length
        },
        setTotalCountOfResponse(state, payload) {
            state.totalCountOfResponse = payload;
        },
        setCurrentSource(state, payload) {
            state.currentSource = payload;
        },
        setSelectedSources(state, payload) {
            state.selectedSources = payload
        },
        setCleanPauses(state) {
            state.pauses = null;
        }
    }
}